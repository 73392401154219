import { createEffect, createEvent, createStore, forward } from "effector-next";
import Router from "next/router";

import { User } from "api/users";
import { logout, getSession } from "api/security";

export const dropSession = createEvent();
export const requestSession = createEvent();
export const saveSession = createEvent<User>();

export const $session = createStore<null | User>(null);
export const $isAuthenticated = $session.map((user) => user !== null);
export const $isReadOnly = $session.map((user) => Boolean(user?.isReadOnly));
export const $isAdmin = $session.map((user) => Boolean(user?.isAdmin));
export const $isBoosterLead = $session.map((user) => Boolean(user?.isBoosterLead));
export const $isBoosterManager = $session.map((user) => Boolean(user?.isManager));

export const $isCanCreate = $session.map((user) => Boolean(user?.isCanCreate));

$session.reset(logout.done);
$session.on(getSession.doneResult, (_, { data }) => data);
$session.on(saveSession, (_, session) => session);

const redirectLogoutFx = createEffect(() => {
  return Router.push("/");
});

forward({
  from: requestSession,
  to: getSession,
});

forward({
  from: dropSession,
  to: logout,
});

logout.done.watch(() => {
  redirectLogoutFx();
});

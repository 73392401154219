import { merge, guard, forward, attach, restore, createEvent, createEffect } from "effector-next";
import axios, { Method, CancelToken, AxiosResponse, AxiosError } from "axios";

import { createUrlWithQuery } from "./create-url-with-query";

export type RequestParams = Omit<OriginalRequestParams, "cookies">;
export type FailResponse<E = unknown, M = void> = AxiosError<FailResult<E, M>>;
export type DoneResponse<D = unknown, M = void> = AxiosResponse<DoneResult<D, M>>;

export interface FailResult<E, M = void> {
  meta: M;
  errors: E;
  message: string;
}

export interface DoneResult<D, M = void> {
  data: D;
  meta: M;
  message: string;
}

export interface OriginalRequestParams {
  url: string;
  method: Method;
  baseURL: string;
  cookies?: string;
  timeout?: number;
  body?: null | object;
  query?: null | object;
  cancelToken?: CancelToken;
  withCredentials?: boolean;
  headers?: Record<string, string>;
}

export const requestFailed = createEvent<FailResponse<any, any>>(); // eslint-disable-line @typescript-eslint/no-explicit-any

export const cookiesForRequestSetted = createEvent<string>();
export const $cookiesForRequest = restore(cookiesForRequestSetted, "");

export const cookiesForResponseSetted = createEvent<string[]>();
export const $cookiesForResponse = restore(cookiesForResponseSetted, []);

export const originalRequestFx = createEffect<
  OriginalRequestParams,
  DoneResponse<any, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  FailResponse<any, any> // eslint-disable-line @typescript-eslint/no-explicit-any
>();

export const requestFx = attach({
  effect: originalRequestFx,
  source: $cookiesForRequest,
  mapParams: (params: RequestParams, cookies) => ({ ...params, cookies }),
});

const headerCookiesReceived = merge([originalRequestFx.doneData, originalRequestFx.failData]).map((res) => {
  const response = "headers" in res ? res : res.response;

  if (typeof response === "undefined" || !response.config.withCredentials) return [];

  const setCookie: string | string[] = response.headers["set-cookie"] ?? [];

  return Array.isArray(setCookie) ? setCookie : [setCookie];
});

forward({
  from: originalRequestFx.failData,
  to: requestFailed,
});

guard({
  source: headerCookiesReceived,
  filter: (cookies) => typeof window === "undefined" && cookies.length > 0,
  target: cookiesForResponseSetted,
});

originalRequestFx.use(
  ({ url, method, baseURL, cookies, timeout, body, cancelToken, withCredentials, query, headers }) => {
    const urlWithQuery = createUrlWithQuery(url, query);
    const requestHeaders = Object.assign({}, headers ?? {});

    if (typeof window === "undefined" && withCredentials) {
      requestHeaders.Cookie = [cookies, requestHeaders?.Cookie].filter(Boolean).join("; ");
    }

    return axios.request({
      method,
      baseURL,
      timeout,
      cancelToken,
      data: body,
      withCredentials,
      url: urlWithQuery,
      headers: requestHeaders,
    });
  },
);

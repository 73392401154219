import { guard, attach, forward, createEvent, Effect, Event } from "effector-next";
import axios, { Method } from "axios";

import { config } from "common/config";

import { requestFx, RequestParams, FailResponse, DoneResponse, FailResult, DoneResult } from "./common";

export type LaunchParams = Omit<RequestParams, keyof ResourceConfig<never> | "baseURL" | "cancelToken">;

export type Resource<Params, Done, Fail, DoneMeta, FailMeta> = Effect<
  Params,
  DoneResponse<Done, DoneMeta>,
  FailResponse<Fail, FailMeta>
> & {
  abort: Event<void | never>;
  failResult: Event<FailResult<Fail, FailMeta>>;
  doneResult: Event<DoneResult<Done, DoneMeta>>;
};

export interface ResourceConfig<Params> {
  method: Method;
  baseURL?: string;
  timeout?: number;
  withCredentials?: boolean;
  ignoreOldAnswers?: boolean;
  mapParams: (params: Params) => LaunchParams;
}

export function createResource<Params, Done, Fail = void, DoneMeta = void, FailMeta = void>({
  method,
  timeout,
  baseURL,
  mapParams,
  withCredentials,
  ignoreOldAnswers,
}: ResourceConfig<Params>): Resource<Params, Done, Fail, DoneMeta, FailMeta> {
  const isServer = typeof window === "undefined";
  let cancelToken = axios.CancelToken.source();

  const cancelRequest = createEvent<string | unknown>();
  const abort = createEvent<void | never>();

  const launch = attach<Params, Effect<RequestParams, DoneResponse<Done, DoneMeta>, FailResponse<Fail, FailMeta>>>({
    effect: requestFx,
    mapParams: (params: Params) => {
      const requestParams = mapParams(params);

      return {
        ...requestParams,
        method,
        timeout,
        withCredentials,
        cancelToken: cancelToken.token,
        baseURL: baseURL ?? config.apiUrl,
      };
    },
  });

  const doneResult = launch.doneData.map((done) => done.data);
  const failResult = createEvent<FailResult<Fail, FailMeta>>();

  guard({
    source: launch.failData.map((fail) => fail?.response?.data),
    filter: (data): data is FailResult<Fail, FailMeta> => Boolean(data),
    target: failResult,
  });

  guard({
    source: abort,
    filter: launch.pending,
    target: cancelRequest,
  });

  if (ignoreOldAnswers) {
    forward({
      from: launch,
      to: cancelRequest,
    });
  }

  cancelRequest.watch(() => {
    if (isServer) return;

    cancelToken.cancel("Request has been canceled");
    cancelToken = axios.CancelToken.source();
  });

  return Object.assign(launch, { abort, doneResult, failResult });
}

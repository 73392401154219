import * as React from "react";
import { useStore } from "effector-react";
import { NextPage } from "next/types";
import { isServer } from "lib/ssr";
import ErrorPage from "next/error";

import { $isReadOnly } from "../../model";

interface InitialPropsResult {
  set403?: Function;
}

export function withAdminAccess<P>() {
  return function (Page: NextPage<P>) {
    const WithAdminAccess: NextPage<P & InitialPropsResult, InitialPropsResult> = ({ set403, ...props }) => {
      const isReadOnly = useStore($isReadOnly);
      let componentProps = props as P;
      let Component = Page;

      if (isReadOnly) {
        if (set403) set403();
        Component = ErrorPage as never;
        componentProps = { statusCode: 403, title: "No access" } as never;
      }

      return <Component {...componentProps} />;
    };

    WithAdminAccess.getInitialProps = async (params) => {
      let initialProps: InitialPropsResult = {};

      if (Page.getInitialProps) {
        initialProps = await Page.getInitialProps(params);
      }

      if (isServer()) {
        const { res } = params;

        initialProps.set403 = () => {
          if (res) {
            res.statusCode = 403;
          }
        };
      }

      return initialProps;
    };

    return WithAdminAccess;
  };
}

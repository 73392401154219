import { createResource } from "lib/request";

import { User } from "./users";

export interface LoginPayload {
  login: string;
  password: string;
}

export const login = createResource<LoginPayload, User>({
  method: "post",
  withCredentials: true,
  ignoreOldAnswers: true,
  mapParams: (params) => ({
    url: `login`,
    body: params,
  }),
});

export const logout = createResource({
  method: "get",
  withCredentials: true,
  mapParams: () => ({
    url: "logout",
  }),
});

export const getSession = createResource<void, User>({
  method: "get",
  withCredentials: true,
  mapParams: () => ({
    url: "session",
  }),
});

import * as React from "react";
import { AppContext, AppProps } from "next/app";
import { NextComponentType } from "next/types";
import { useStore } from "effector-react";
import { useRouter } from "next/router";
import ErrorPage from "next/error";

import { isServer } from "lib/ssr";

import { deleteCookie, setCookie } from "cookies-next";

import { $isAuthenticated, dropSession } from "../../model";

type NextApp<P = {}, IP = P> = NextComponentType<AppContext, IP, P & AppProps>;

interface InitialPropsResult {
  set401?: Function;
  isReal404?: boolean;
}

export function withAuth<P>(redirectUrl: string) {
  return function (App: NextApp<P>) {
    const WithAuth: NextApp<P & InitialPropsResult, InitialPropsResult> = (props) => {
      const router = useRouter();
      const isAuthenicated = useStore($isAuthenticated);

      const { set401, isReal404 } = props;
      let { pageProps, Component } = props;

      const isUrlsEqual = router.pathname === redirectUrl;
      const isNotAuthorized = !isReal404 && !isAuthenicated && !isUrlsEqual;

      if (isNotAuthorized) {
        dropSession();
        deleteCookie("biscuit");
        if (set401) set401();

        Component = ErrorPage;
        pageProps = { statusCode: 401, title: "Authorization required" };
      }

      return <App {...props} pageProps={pageProps} Component={Component} />;
    };

    WithAuth.getInitialProps = async (params) => {
      let initialProps: InitialPropsResult = {};

      if (App.getInitialProps) {
        initialProps = await App.getInitialProps(params);
      }

      if (isServer()) {
        const { ctx } = params;

        initialProps.isReal404 = ctx.res?.statusCode === 404;

        initialProps.set401 = () => {
          dropSession();
          deleteCookie("biscuit");
          setCookie("biscuit", null);
          ctx.res!.statusCode = 307;
          ctx.res!.setHeader("Location", "/");
        };
      }

      return initialProps;
    };

    return WithAuth as never;
  };
}

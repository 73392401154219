export interface AppConfig {
  apiUrl: string;
  isProduction: boolean;
  appEnvironment?: string;
}

const isServer = typeof window === "undefined";

export const config: AppConfig = {
  isProduction: process.env.NODE_ENV === "production",
  appEnvironment: process.env.NEXT_PUBLIC_APP_ENVIRONMENT,
  apiUrl: isServer ? process.env.NEXT_PUBLIC_API_URL ?? "" : "/api/",
};

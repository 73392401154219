import { isNil, isEmpty, either, unless, concat } from "ramda";

/**
 * Create url with query string
 * @param {string} path - Resource path
 * @param {object} params - Query params as object
 *
 * @example
 * const url = createUrlWithQuery("https://localhost", { name: 'someName' }); // https://localhost?name=someName
 */
export function createUrlWithQuery<T extends {} = {}>(path: string, params?: null | T) {
  if (isNil(params)) return path;

  return `${path}${createQueryString(params)}`;
}

const isBadValue = either(isNil, isEmpty);
const concatWithMark = unless(isEmpty, concat("?"));
const createQueryString = <T extends {} = {}>(params: T) => {
  const paramsEntries = Object.entries<string>(params);

  const queryString = paramsEntries.reduce((accum, [key, value]) => {
    if (isBadValue(value)) return accum;

    if (accum) return `${accum}&${key}=${value}`;

    return `${accum}${key}=${value}`;
  }, "");

  return concatWithMark(queryString);
};
